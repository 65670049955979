import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { Link } from "gatsby-plugin-intl"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { signup } from "../../api/auth"
import { useAnalytics } from "../../hooks/useTracking"
import LogoShort from "../../images/logo_short.svg"
import MailImage from "../../images/mail_opened.svg"
import MastercardImage from "../../images/master-card.svg"
import Spinner from "../../images/spinner.svg"
import VisaImage from "../../images/visa.svg"
import { emailPattern, passPattern, useTranslation } from "../../utils"
import Input from "../input"
import "./styles.css"
import classNames from "classnames"

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",
      color: "#5E6977",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#EE3F3F",
    },
  },
}
const CVV_OPTIONS = Object.assign({ placeholder: "CVV" }, ELEMENT_OPTIONS)

const SignUpForm = ({ tariff = "STANDARD_V4", cycle = "MONTHLY" }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({})
  const { register, handleSubmit, errors } = useForm()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const ref_key = window.localStorage.getItem("ref_key")
  const analytics = useAnalytics()
  const elements = useElements()
  const stripe = useStripe()

  const onSubmit = async data => {
    if (step === 1) {
      const { name, email, promocode } = data
      analytics.next(name, email, promocode)
      setFormData(data)
      setStep(2)
    } else {
      setLoading(true)
      const stripeToken = await stripe.createToken(
        elements.getElement(CardNumberElement)
      )
      console.log({ ...data, ...formData })
      const success = await signup({
        ...data,
        ...formData,
        tariff,
        cycle,
        ref_key,
        cardToken: stripeToken?.token?.id,
      })
      if (success) {
        analytics.login()
        setStep(3)
      } else {
        setStep(1)
      }
      setLoading(false)
    }
  }

  const tariffStr =
    tariff === "SINGLE_V4"
      ? "Single"
      : tariff === "STANDARD_V4"
      ? "Standard"
      : tariff === "PROFESSIONAL_V4"
      ? "Professional"
      : null
  const prices = {
    Single: [8, 7],
    Standard: [10, 8],
    Professional: [18, 16],
  }

  const price = cycle === "YEARLY" ? prices[tariffStr][1] : prices[tariffStr][0]

  return (
    <div>
      {loading && (
        <div className="wt-loader-wrapper">
          <img src={Spinner} alt="spinner" />
        </div>
      )}
      {step === 3 ? (
        <div className="sign-up-finish">
          <div className="sign-up-logo">
            <img src={LogoShort} alt="Logo" />
          </div>

          <img src={MailImage} alt="Mail" />
          <div className="sign-up-finish-title">
            {t("Your verification was sent")}
          </div>
          <div className="sign-up-finish-text">{t("Check your email")}</div>
        </div>
      ) : (
        <>
          <div className="sign-up-form">
            {step === 1 && (
              <div className="sign-up-form-tariff">
                <p className="sign-up-form-tariff__title">{tariffStr}</p>
                <div className="sign-up-form-tariff__content">
                  <p className="sign-up-form-tariff__price">${price}/mo</p>
                  {cycle === "YEARLY" && (
                    <p className="sign-up-form-tariff__description">
                      ${price * 12} Billed Annually. Cancel anytime.
                    </p>
                  )}
                </div>
              </div>
            )}
            <h2 className="sign-up-title">{t("Sign up")}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <>
                  <Input
                    label={t("Your Name")}
                    placeholder={t("Your Name")}
                    name="name"
                    className={classNames("wt-margin-top-15", {
                      "wt-input_red-border": errors.name,
                    })}
                    ref={register({ required: true })}
                  />
                  <Input
                    label={t("Your Email")}
                    placeholder={t("Your Email")}
                    name="email"
                    className={`wt-margin-top-15 ${
                      errors.email ? "wt-input_red-border" : null
                    }`}
                    ref={register({ required: true, pattern: emailPattern })}
                  />

                  <Input
                    label={t("Password")}
                    placeholder={t("Enter password")}
                    className={`wt-margin-top-15 ${
                      errors.password ? "wt-input_red-border" : null
                    }`}
                    name="password"
                    ref={register({ required: true, pattern: passPattern })}
                    type="password"
                  />
                  {errors.password ? (
                    <span className="wt-input__err-label">
                      {t(
                        "The password does not meet the required criteria. Please choose a password between 8 and 14 characters long, containing at least one numeric digit, one uppercase letter, one lowercase letter, and one special character."
                      )}
                    </span>
                  ) : null}

                  <Input
                    label={t("Do you have promocode?")}
                    placeholder={t("Promocode")}
                    className={`wt-margin-top-15 ${
                      errors.promocode ? "wt-input_red-border" : null
                    }`}
                    name="promocode"
                    ref={register()}
                  />
                  <div className="wt-flex-column wt-margin-top-24">
                    <button
                      className="wt-button wt-button--success wt-button--small border-radius-1000"
                      type="submit"
                    >
                      {t("Next")}
                    </button>
                    <p className="sign-up-hint">
                      By registering you agree to the WebTotem{" "}
                      <Link className="sign-up-hint__link" to="/terms">
                        Terms of Use
                      </Link>
                    </p>
                  </div>
                </>
              )}
              {step === 2 && <CardStep />}
            </form>
          </div>
          {step === 1 && (
            <div className="sign-up-footer">
              <span>{t("Already have an account?")}</span>
              <Link to="/sign-in">{t("Login")}</Link>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const CardStep = () => {
  const { t } = useTranslation()
  const [cardName, setCardName] = useState("")
  return (
    <div className="wt-flex-1">
      <div className="card-step__form">
        <div className="wt-margin-bottom-20">
          <label htmlFor="cardNumber" className="wt-input__label">
            {t("Credit Card Number")}
          </label>
          <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
        </div>

        <div className="wt-flex wt-margin-bottom-20">
          <div className="wt-flex-1">
            <label htmlFor="expiry" className="wt-input__label">
              {t("Expiry Date")}
            </label>
            <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
          </div>
          <div className="wt-flex-1 wt-margin-left-25">
            <label htmlFor="cvc" className="wt-input__label">
              {t("CVV Code")}
            </label>
            <CardCvcElement id="cvc" options={CVV_OPTIONS} />
          </div>
        </div>

        <div className="wt-margin-bottom-20">
          <Input
            value={cardName}
            onChange={e => setCardName(e.target.value.toUpperCase())}
            label={t("Name on Card")}
          />
        </div>

        <div className="wt-flex">
          <button type="submit" className="wt-button wt-button--success">
            Save card
          </button>
          <img
            src={VisaImage}
            className="wt-margin-left-auto wt-margin-right-10"
            alt="visa"
            width={44}
          />
          <img src={MastercardImage} alt="mastercard" width={44} />
        </div>
      </div>
    </div>
  )
}

export default SignUpForm
